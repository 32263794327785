import React from "react"
import css from "./select.css"

export default function Select(props) {
  const {
    placeholder = "",
    options,
    name,
    id,
    label,
    reference,
    value,
    hasError,
    onChange,
    errorMessage = "Por favor, selecciona una opción",
  } = props

  return (
    <div css={css} className={hasError ? "error" : ""}>
      <label className={value ? "float" : ""} for={name}>
        {label}
      </label>
      <select name={name} id={id} ref={reference} onBlur={onChange}>
        <option hidden disabled selected>
          {placeholder}
        </option>
        {options.map(({ label, value }) => (
          <option value={value} selected={props.value === value}>
            {label}
          </option>
        ))}
      </select>

      {hasError ? <span>{errorMessage}</span> : <></>}
    </div>
  )
}
