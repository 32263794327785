import { css } from "@emotion/core"

export default css`
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
`
