import React, { useRef } from "react"

import useOffset from "../hooks/useOffset"

import css from "./parallax.css"

export default function Parallax({ img, speed }) {
  const ref = useRef(null)
  const [offset] = useOffset({ speed, node: ref.current })

  return (
    <div
      css={css}
      ref={ref}
      style={{
        backgroundImage: `url(${img.fluid.src})`,
        minHeight: "100vh",
        transform: `translateY(${offset}px)`,
      }}
    ></div>
  )
}
