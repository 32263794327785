import React from "react"
import css from "./input.css"

export default function Input(props) {
  const {
    id,
    name,
    value,
    label,
    placeholder,
    onInput,
    onChange,
    onBlur,
    reference,
    className,
    hasError = false,
    errorMessage = "Por favor, comprueba que tiene un valor correcto",
  } = props

  return (
    <div css={css} className={`${className} ${hasError ? "error" : ""}`}>
      <input
        {...{
          ...(onInput && { onInput }),
          ...(onChange && { onChange }),
          ...(onBlur && { onBlur }),
          ...(value && { value }),
          ref: reference,
          placeholder,
          name,
          id,
        }}
      ></input>
      <label className={value ? "float" : ""} htmlFor={name}>
        {label}
      </label>
      {hasError ? <span>{errorMessage}</span> : <></>}
    </div>
  )
}
