import React from "react"
import { Link } from "gatsby"

import Separator from "./separator"
import Section from "./section"

export default function Share() {
  return (
    <Section
      style={{
        margin: "5rem 0",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          marginBottom: "1rem",
        }}
      >
        Crea la playlist con nosotros
      </h3>
      <Separator />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          lineHeight: "35px",
          marginTop: "1rem",
        }}
      >
        <Link
          to={
            "https://open.spotify.com/playlist/5QikPVaqtGMiCY1ZMB9qkz?si=coYJBvLSRu6bmXFGNeFTEA"
          }
        >
          <span
            style={{
              color: "#1ED760",
              fontSize: "45px",
            }}
            className={"icon-spotify"}
          ></span>
        </Link>
      </div>
    </Section>
  )
}
