import { css } from "@emotion/core"

export const group = css`
  margin: 1.3rem 0;

  span {
    color: #a4a4a4;
    font-family: "Great Vibes", cursive;
    font-size: 1.7rem;
  }
  .optionsContainer {
    margin-top: 1.3rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`

export const option = css`
  font-family: "Cinzel";
  background: none;
  font-size: 1.3rem;
  width: 100%;
  border: 1px solid #c9c9c9;
  color: #c9c9c9;

  label {
    transition: all 0.5s;
    cursor: pointer;
    display: block;
    padding: 0.7rem;
  }

  input {
    display: none;
    &:checked ~ label {
      border: 1px solid #a4a4a4;
      color: #a4a4a4;
    }
  }
`
