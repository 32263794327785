import { css } from "@emotion/core"

export default css`
  margin: 0 0.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    top: 1rem;
    left: 1rem;
    left: 0;
    position: absolute;
    font-family: "Great Vibes", cursive;
    font-size: 1.7rem;
    color: #a4a4a4;
    transition: all 0.3s;
    &.float {
      top: 7px;
      left: 7px;
      font-size: 1.2rem;
    }
  }
  input {
    border: none;
    line-height: 1.3rem;
    font-size: 1rem;
    border-bottom: 1px solid #c9c9c9;
    padding: 1.7rem 1rem 0.3rem 1.7rem;
    background-color: transparent;
    &:focus {
      border-bottom: 1px solid #000;
    }
    &:focus + label {
      color: #000;
      top: 7px;
      left: 7px;
      font-size: 1.2rem;
    }
  }
  span {
    color: #c38e9e;
    position: absolute;
    bottom: 3px;
    left: 3px;
  }
  &.error {
    label {
      color: #c38e9e !important;
    }
    input {
      border-bottom: 1px solid #c38e9e;
    }
  }
`
