import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import RSVPForm from "../components/rsvp-form"
import Section from "../components/section"
import Parallax from "../components/parallax"
import Separator from "../components/separator"
import Playlist from "../components/playlist"

const { XL, L, M, S, XS } = SIZES

export default function RSVP() {
  const { hero, rsvpForm } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const config = {
    padding: {
      [XL]: 4,
      [L]: 4,
      [M]: 4,
      [S]: 4,
      [XS]: 2,
    },
  }

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Confirma tu asistencia</h2>
        <Separator />
        <p>
          ¿Cómo? ¿Que aún no has confirmado? ¡Que te quedas sin sitio! Llama o
          escribe YA a los novios o rellena el formulario de aquí abajo cuanto
          antes.
        </p>
        <p>
          Si lo necesitas, podrás modificar la información de la confirmación
          utilizando este mismo formulario
        </p>
      </Section>
      <Section>
        <Parallax img={rsvpForm.childImageSharp} speed={0} />
        <RSVPForm
          style={{
            maxWidth: "400px",
            backgroundColor: "rgba(255,255,255,0.9)",
            padding: `${config.padding[viewportSize]}rem`,
            margin: "5rem auto",
            minHeight: "300px",
          }}
        />
      </Section>
      <Playlist />
    </Layout>
  )
}
