import React from "react"
import { option as optionCSS, group as groupCSS } from "./radiogroup.css"

const RadioOption = ({ label, value, name, reference, key }) => {
  return (
    <div key={key} css={optionCSS}>
      <input
        id={`${name}-${value}`}
        ref={reference}
        value={value}
        type="radio"
        name={name}
      ></input>
      <label htmlFor={`${name}-${value}`}>{label}</label>
    </div>
  )
}

export default function RadioGroup(props) {
  const { reference, options, label, name } = props

  return (
    <div css={groupCSS}>
      <span>{label}</span>
      <div className={"optionsContainer"}>
        {options.map((option, i) => {
          const label = option.label || option
          const value = option.value || option

          return RadioOption({
            reference,
            label,
            value,
            name,
            key: i,
          })
        })}
      </div>
    </div>
  )
}
