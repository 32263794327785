import { css } from "@emotion/core"

export default css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 100%;
  }

  button {
    margin-top: 5rem;
  }

  button {
    margin-top: 5rem;
  }

  .message {
    text-align: center;
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }
`
