import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import { useForm } from "react-hook-form"
import { range } from "ramda"
import axios from "axios"

import Input from "./input"
import RadioGroup from "./radiogroup"
import Select from "./select"
import Button from "./button"

import css from "./rsvp-form.css"

const Loading = function () {
  return (
    <div className="message">
      <p>Estamos terminando de buscar una mesa para ti</p>
    </div>
  )
}

const SuccessMessage = function () {
  return (
    <div className="message">
      <h3>¡Gracias por confirmar!</h3>
      <p>Nos alegra poder contar contigo</p>
    </div>
  )
}

const ErrorMessage = function () {
  return (
    <div className="message">
      <p>No hemos podido confirmar tu asistencia correctamente</p>
      <p>
        Inténtalo pasado un rato o ponte en contacto con nosotros directamente
      </p>
    </div>
  )
}

const Form = function ({ onSubmit }) {
  const { register, handleSubmit, errors, watch } = useForm()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        className={"fullWidth"}
        reference={register({ required: true })}
        hasError={errors.name}
        value={watch("name")}
        label="Nombre y apellidos"
        name="name"
        id="name"
      ></Input>
      <Input
        className={"fullWidth"}
        reference={register({
          pattern: /^[\w.-]+@(?:[\w-]+\.)+\w+$/,
          required: true,
        })}
        hasError={errors.email}
        value={watch("email")}
        label="Email"
        name="email"
        id="email"
      ></Input>

      <RadioGroup
        reference={register()}
        name={"isAttending"}
        label={"¿Puedes asistir a la boda?"}
        options={[
          {
            label: "Puedo asistir",
            value: "true",
          },
          {
            label: "No puedo asistir",
            value: "false",
          },
        ]}
      ></RadioGroup>

      {watch("isAttending") === "true" ? (
        <>
          <Select
            reference={register({ required: true })}
            label={"¿Cuántos sereis?"}
            name={"assistants"}
            value={watch("assistants")}
            hasError={errors.assistants}
            options={range(0, 10).map(i => ({
              label: i ? i + 1 : "Iré solo",
              value: i + 1,
            }))}
          ></Select>
          <Input
            className={"fullWidth"}
            reference={register({ required: false })}
            value={watch("intolerances")}
            label="Intolerancias"
            name="intolerances"
            id="intolerances"
          ></Input>
        </>
      ) : (
        <></>
      )}

      {watch("isAttending") && <Button type={"submit"}>Enviar</Button>}
    </form>
  )
}

export default function RSVPForm({ style }) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const notify = msg => {
    firebase.analytics().logEvent(`rsvp_${msg}`)
  }

  const onSubmit = data => {
    setLoading(true)
    notify("in-progress")
    axios
      .post(
        "https://us-central1-aranchaypablo-57b6a.cloudfunctions.net/rsvp",
        data
      )
      .then(() => {
        setSuccess(true)
        notify("success")
      })
      .catch(() => {
        setError(true)
        notify("error")
      })
      .then(() => setLoading(false))
  }

  return (
    <div style={style} css={css}>
      {loading ? (
        <Loading />
      ) : success ? (
        <SuccessMessage />
      ) : error ? (
        <ErrorMessage />
      ) : (
        <Form {...{ onSubmit }} />
      )}
    </div>
  )
}
